<template>
    <div class="rm-content">
      <div class="primary-section-content">
        <div  class="mr-6">
          <v-expansion-panels
            elevation="0"
            class="hb-expansion-panel"
            v-model="panel"
            :key="level"
            multiple
          >
            <hb-expansion-panel v-if="!isPropertyLevel"  key="rent">
                <template v-slot:title> Account Information</template>
                <template v-slot:content>
                    <hb-form label="Direct Debit*">
                        <div class="hb-text-night-light">
                            Provide details for your direct debit account
                        </div>
                            <v-col cols="12">
                                <v-text-field
                                hide-details
                                :class="{'custom-field-error' : errors.first('account_name')}"
                                v-model="payment_method.account_name"
                                v-validate="'required|max:32'"
                                data-vv-name="account_name"
                                data-vv-as="Account Name"
                                :error-messages="errors.collect('account_name')"
                                label="Account Name*"
                                id="account-name"
                                name="account-name"
                                autocomplete="cc-account-name"
                                @input="handleInputUpdate('payment_method','account-name',$event)"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                :class="{'custom-field-error' : errors.first('bsb_number')}"
                                tile
                                hide-details
                                v-model="payment_method.bsb_number"
                                data-vv-name="bsb_number"
                                v-validate="'required|numeric|length:6'"
                                maxlength="6"
                                data-vv-as="BSB Number"
                                :error-messages="errors.collect('bsb_number')"
                                label="BSB Number*"
                                id="account-bsb-number"
                                name="account-bsb-number"
                                autocomplete="cc-account-bsb-number"
                                @input="handleInputUpdate('payment_method','bsb_number',$event)"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col>
  
                                <v-text-field
                                :class="{'custom-field-error' : errors.first('account_number')}"
                                tile
                                hide-details
                                v-model="payment_method.account_number"
                                data-vv-name="account_number"
                                v-validate="'required|numeric|min:4|max:10|'"
                                minlength='4'
                                maxlength="10"
                                data-vv-as="Account Number"
                                :error-messages="errors.collect('account_number')"
                                label="Account Number*"
                                id="account-number"
                                name="account-number"
                                autocomplete="cc-account-number"
                                @input="handleInputUpdate('payment_method','account_number',$event)"
                                >
                                </v-text-field>
                            </v-col>
                    </hb-form>
                    <hb-form label="Autopay">
                        <hb-switch v-model="payment_method.enable_auto_pay" label="Enable Autopay"></hb-switch>
                    </hb-form>
                    <hb-bottom-action-bar @close="fetchData" >
                      <template v-slot:right-actions >
                        <hb-btn @click="logdata" :disabled = "errors.items.length>0 || !hasRolePermission('enable_disable_autopay')">Save</hb-btn>
                      </template>
                    </hb-bottom-action-bar>

                    
                </template>
            </hb-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
</template>
  
  <script type="text/babel">
  import { mapGetters } from "vuex";
  
  import api from "../../../assets/api.js";
  import { capitalizeFirstLetter } from "../../../utils/common";
  import { notificationMixin } from  '../../../mixins/notificationMixin.js';
  
  export default {
    name: "national-accounts",
    mixins: [notificationMixin],
    props: {
      level: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        payment_method: {
            account_number:"",
            bsb_number:"",
            account_name:"",
            enable_auto_pay:""

        },
      };
    },
    components: {
    },
    created () {
    this.fetchData()
  },
    computed: {
      ...mapGetters({
        properties: "propertiesStore/filtered",
        hasRolePermission: "authenticationStore/rolePermission",
      }),
      currentLevel() {
        return this.level.toLowerCase();
      },
  
      isPropertyLevel() {
        return this.level === "Property";
      },
  
      
    
    },
  
    watch: {
      isPropertyLevel: {
        async handler(val) {
          if (val) {
            if (this.properties.length > 1) {
              this.propertyId = "";
              this.hasProperties = true;
            } else if (this.properties.length === 1) {
              this.propertyId = this.properties[0].id;
              this.hasProperties = false;
            } else this.propertyId = "";
            if (this.propertyId) await this.getDocumentList();
          }
        },
        immediate: true,
      },
      propertyId: {
        async handler(val) {
          if (this.isPropertyLevel && val) {
            this.propertyLoading = true;
            this.revenueArray = [];
            await this.getDocumentList();
            await this.fetchPropertySpaceGroup();
          }
        },
        immediate: true,
      },
      rentEnabled: {
        handler(val) {
          if (val) {
            this.readonlyRent = true;
          }
        },
        immediate: true,
      },
    },
  
    methods: {
      async fetchData() {
        let data = await api.get(this, api.NATIONAL_ACCOUNTS)
        this.payment_method = data;
      }, 
      async logdata() {
        await api.put(this, api.NATIONAL_ACCOUNTS, this.payment_method)
        this.showMessageNotification({ type: 'success', description: 'You updated Direct Debit information.' })
      },

    }
  };
  </script>
  
  <style lang="scss">
  .notificationMsg {
    textarea {
      line-height: 35px;
    }
  }
  
  .merge-field-text-wrap {
    a {
      i {
        margin-bottom: 2px;
      }
    }
  }
  </style>
